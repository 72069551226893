import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"amp-module-page"},[_c('amp-row-first',{scopedSlots:_vm._u([{key:"input",fn:function(){return [(_vm.video.id && !_vm.isEditable)?_c('status-chip',{staticClass:"mb-3",class:{
                    'amp-module-element-hoisted':
                        _vm.$vuetify.breakpoint.mdAndUp
                },attrs:{"large":_vm.$vuetify.breakpoint.mdAndUp,"status":_vm.status}}):_vm._e(),(_vm.isLoading)?_c(VSkeletonLoader,{staticClass:"pt-2 pb-1",attrs:{"loading":"","type":"heading"}}):_c('h2',{staticClass:"font-weight-medium text-justify"},[_vm._v(" "+_vm._s(_vm.video.title || ' ')+" ")]),_c(VDivider,{staticClass:"my-4"})]},proxy:true}])}),_c('amp-row-last',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('a-infographic',{staticClass:"py-3",attrs:{"label":"Preview","template":_vm.video.video_template,"readonly":""}}),_c(VCol,{staticClass:"text-right px-0 pt-0",attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"text":"","block":_vm.$vuetify.breakpoint.smAndDown,"disabled":_vm.isLoading,"target":"_blank","href":_vm.imageLink}},[_c(VIcon,{attrs:{"small":"","left":""}},[_vm._v("image")]),_vm._v(" View Image ")],1)],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }