<template>
    <v-container class="amp-module-page">
        <amp-row-first>
            <template #input>
                <status-chip
                    v-if="video.id && !isEditable"
                    class="mb-3"
                    :class="{
                        'amp-module-element-hoisted':
                            $vuetify.breakpoint.mdAndUp
                    }"
                    :large="$vuetify.breakpoint.mdAndUp"
                    :status="status"
                />
                <v-skeleton-loader
                    v-if="isLoading"
                    loading
                    type="heading"
                    class="pt-2 pb-1"
                />
                <h2 v-else class="font-weight-medium text-justify">
                    {{ video.title || '&#160;' }}
                </h2>

                <v-divider class="my-4" />
            </template>
        </amp-row-first>

        <amp-row-last>
            <template #input>
                <a-infographic
                    label="Preview"
                    :template="video.video_template"
                    class="py-3"
                    readonly
                />
                <v-col cols="12" class="text-right px-0 pt-0">
                    <v-btn
                        text
                        :block="$vuetify.breakpoint.smAndDown"
                        :disabled="isLoading"
                        target="_blank"
                        :href="imageLink"
                    >
                        <v-icon small left>image</v-icon>
                        View Image
                    </v-btn>
                </v-col>
            </template>
        </amp-row-last>
    </v-container>
</template>

<script lang="ts">
import Component, { mixins } from 'vue-class-component';
import { mapGetters } from 'vuex';

import { Endpoint } from '@/mixins';
import { InjectReactive, Watch } from '@/utils/decorators';

import { AInfographic } from '@/components/AInfographic';
import { StatusChip } from '@/components/StatusChip';

import type { AmpModules } from '@/types/Announcement';
import {
    AmpRow,
    AmpRowFirst,
    AmpRowLast
} from '@/components/AmpModule/AmpPage';
import type { Infographic as TInfographic } from '@/types/Infographic';
import type { ModuleLink } from '@/types/ModuleLink';

@Component({
    components: {
        AmpRow,
        AmpRowFirst,
        AmpRowLast,
        AInfographic,
        StatusChip
    },
    computed: {
        ...mapGetters('user', ['isClient'])
    }
})
export default class Infographic extends mixins(Endpoint) {
    isClient!: boolean;

    @InjectReactive({
        from: 'modules',
        default() {
            return null;
        }
    })
    modules!: AmpModules;

    @Watch('modules')
    onModulesChanged() {
        this.onMounted();
    }

    video: Partial<TInfographic> = {};

    endpoint = '/infographics/review';

    get link(): ModuleLink[] {
        return [
            {
                type: 'primary',
                label: 'Edit',
                to: this.editLink,
                warn: false //this.isClient
            }
        ];
    }

    get moduleId() {
        return this.modules?.infographic_id;
    }

    get announcementId() {
        return this.$route.params.announcementId;
    }

    get sourceUrl() {
        return [this.endpoint, this.moduleId].join('/');
    }

    get editLink() {
        return `/announcements/edit/${this.announcementId}/infographic`;
    }

    get imageLink() {
        return `/infographics/image/${this.video.id}`;
    }

    get isEditable() {
        return this.video.is_editable;
    }

    get status() {
        return this.video.status_string ?? '';
    }

    onMounted() {
        if (this.moduleId) {
            this.load();
        } else {
            this.edit();
        }
    }

    onData(data: { entity: TInfographic }) {
        this.video = data.entity;

        this.emitLinks();
    }

    emitLinks() {
        this.$emit('links', this.isEditable ? this.link : []);
    }

    edit() {
        this.$router.push(this.editLink);
    }
}
</script>
