<template>
    <validation-provider
        ref="provider"
        slim
        :detect-input="false"
        :rules="rules"
        :name="fieldName"
        :vid="$attrs.vid"
    >
        <v-container v-resize="onResize" class="pa-0">
            <v-container
                ref="container"
                class="amp-infographic-preview"
                :style="containerStyle"
                :class="{ zoom: isFullScreen, light: useLightTheme }"
            >
                <a-box-actions
                    v-if="!isLoading"
                    :options="actionOptions"
                    :readonly="readonly"
                    @change="change"
                    @media-deleted="onMediaDeleted"
                />
                <div class="text-right pa-1 actions">
                    <a-theme-toggle @toggle-theme="toggleTheme" />
                    <a-full-screen @toggle-zoom="toggleZoom" />
                </div>
                <div class="slide-container" :style="slideStyle">
                    <v-progress-circular
                        v-if="isLoading"
                        class="progress-indicator"
                        indeterminate
                        color="white"
                        size="350"
                        width="30"
                    />
                    <template v-else>
                        <a-video-box
                            v-for="(box, i) in getVisibleBoxes()"
                            :key="box.id"
                            :box="box"
                            :index="i"
                            :readonly="readonly"
                            @change="change"
                            @action="onBoxAction"
                        />
                    </template>
                </div>
            </v-container>
        </v-container>
    </validation-provider>
</template>

<script lang="ts">
import { CSSProperties } from 'vue';
import Component from 'vue-class-component';
import { ProvideReactive } from '@/utils/decorators';

import { ValidationProvider } from 'vee-validate';

import { MediaModuleSlide } from '@/mixins';

import ABoxActions from '@/components/ASlideshow/SlideActions/ABoxActions.vue';

import AFullScreen from '@/components/ASlideshow/SlideActions/AFullScreen.vue';
import AThemeToggle from '@/components/ASlideshow/SlideActions/AThemeToggle.vue';

import { AVideoBox } from '@/components/AVideoBox';

@Component({
    components: {
        AThemeToggle,
        AFullScreen,
        ABoxActions,
        AVideoBox,
        ValidationProvider
    }
})
export default class AInfographic extends MediaModuleSlide {
    linkValidation = false;

    containerStyle: CSSProperties = {};

    zoomedToolbarHeight = 44;

    @ProvideReactive() isFullScreen = false;
    @ProvideReactive() useLightTheme = false;

    getVisibleBoxes() {
        return this.boxes.filter(box => !box.video_zoom_box);
    }

    toggleZoom() {
        this.isFullScreen = !this.isFullScreen;

        setTimeout(() => this.onResize(), 300);
    }

    toggleTheme() {
        this.useLightTheme = !this.useLightTheme;
    }

    computeScale() {
        if (this.width && this.height) {
            if (this.isFullScreen) {
                const { width, height } = this.getContainerBoxRect();

                const scale =
                    width < height ? width / this.width : height / this.height;

                const adjustedScale =
                    (this.height * scale - this.zoomedToolbarHeight) /
                    this.height;

                this.updateContainerHeight(`${this.height * adjustedScale}px`);
                // allow for smooth animation
                this.$nextTick(() => {
                    this.scale = adjustedScale;
                });
            } else {
                const { width, height } = this.getContainerBoxRect();
                const scale =
                    width < height ? width / this.width : height / this.height;

                this.updateContainerHeight(`${this.height * scale}px`);
                // allow for smooth animation
                this.$nextTick(() => {
                    this.scale = scale;
                });
            }
        }
    }

    updateContainerHeight(height: string) {
        this.containerStyle = {
            height: height,
            maxHeight: height
        };
    }
}
</script>

<style lang="scss" scoped>
.amp-infographic-preview::v-deep {
    position: relative;
    background-color: $black;
    border: 1px solid transparent;
    width: 100%;
    height: calc(45vw / 9 * 16);
    min-height: 350px !important;
    margin-top: 44px;
    padding: 0;
    transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

    .actions {
        position: relative;
        background-color: $black;
        margin-top: -44px;
        margin-left: -1px;
        width: calc(100% + 2px);
        z-index: 2;
    }

    &.zoom {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        height: 100% !important;
        width: 100% !important;
        max-width: 100% !important;
        max-height: calc(100vh - 44px);
        min-height: -webkit-fill-available !important;
        min-width: -webkit-fill-available !important;

        border: none;

        .actions {
            margin-left: 0;
            width: 100%;
        }
    }

    &.light {
        background-color: $grey-light-darker;

        .actions {
            background-color: lighten($boulder, 18%);
        }
    }

    .slide-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transition: transform 0.5s ease-in-out;
        transform: translate(-50%, -50%);
        transform-origin: top left;
    }

    .progress-indicator {
        transform: translate(-50%, -50%);
    }

    .video-box {
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
}
</style>
